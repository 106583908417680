<template>
  <div class="Conferencias">
    <section class="pt160 pb160 pt-xs-80 pb-xs-80 image-bg overlay">
      <div class="background-image-holder">
        <img
          alt="Pic"
          class="background-image"
          src="@/assets/conferencias-banner.jpg"
        />
      </div>

      <div class="container">
        <div class="row">
          <div class="col-sm-12 text-center">
            <h1 class="uppercase mb0">Conferencias & Masterclass</h1>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container">
        <div class="row">
          <div class="col-sm-12 text-center">
            <div class="table-responsive">
              <table class="table table-responsive mb0 timetable">
                <thead>
                  <tr>
                    <th class="col-sm-2">Fecha</th>
                    <th class="col-sm-2">Hora</th>
                    <th class="col-sm-4">Título</th>
                    <th class="col-sm-2">Imparte</th>
                    <th class="col-sm-2">Lugar</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(cnf, idx) in conferencias"
                    :key="idx"
                    data-toggle="tooltip"
                    data-placement="top"
                    :title="cnf.titulo_con"
                  >
                    <td>{{ cnf.fecha_con }}</td>
                    <td>
                      {{
                        $Datetime
                          .fromSQL(cnf.fecha_con + " " + cnf.hora_con)
                          .setLocale("es-Mx")
                          .toFormat("h:mm a")
                      }}
                    </td>
                    <td>{{ cnf.titulo_con }}</td>
                    <td>{{ cnf.imparte_con }}</td>
                    <td>{{ cnf.lugar_con }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="image-bg">
      <div class="container">
        <div class="row">
          <div
            class="
              col-md-10 col-md-offset-1 col-sm-12
              pt120
              pb120
              pt-xs-80
              pb-xs-80
              bg-dark
              text-center
            "
            style="background-color: #963d5a"
          >
            <h4 class="uppercase mb40 mb-xs-24">
              Diplomado BioEmoción Inteligente
            </h4>
            <p class="p32 mb40 mb-xs-24">
              Una formación terapéutica de alto nivel consiste en llevar al
              alumno a la adquisición de conocimiento teórico, a una nueva toma
              de consciencia personal, a ser generoso para generar abundancia, a
              conocer un nuevo estilo de vida basado en el equilibro del Estado
              del Ser. <br />“El terapeuta emocional reproduce lo que él es en
              su acompañamiento de sanación del otro”.
            </p>
            <router-link class="btn mb0" to="/diplomados"
              >Más información</router-link
            >
          </div>
        </div>
      </div>
    </section>

    <section data-sr="enter bottom move 20px over .5s and scale 0% and wait 0s">
      <div class="container">
        <br /><br />
        <div class="row mb64 mb-xs-32">
          <div class="col-sm-12 text-center">
            <div class="bg-secondary pt64 pb64">
              <h4 class="uppercase mb16">Centro de conocimiento</h4>
              <p>
                Entendemos que las conferencias no son para todos los gustos,
                por eso te invitamos a que explores nuestro centro de
                conocimiento
              </p>
              <router-link class="btn btn-filled" to="/conocimiento"
                >Explorar el centro de conocimiento</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import forms from "@/mixins/forms.js";
export default {
  name: "Conferencias",
  mixins: [forms],
  data() {
    return {
      conferencias: [],
    };
  },
  created() {
    this._getThings("conferencias?limit=12", "conferencias");
  },
};
</script>